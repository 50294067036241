/**
 * Theme Name: Highly Prospective
 * Author: Anthony Pipkin
 * Author URI: https://highlyprospective.com/
 * Version: 1.1.0
 */

@import "imports";

$top-bar--height: 5.1rem;

html {
    font-size: 62.5%;
}

body {
    margin: 0;
    font-family: $font--secondary;
    font-size: 1.5rem;
    font-weight: 300;

    @media screen and (min-width: 42em) {
        font-size: 1.8rem;
    }
}

h1,h2,h3,h4,h5,h6 {
    font-family: inherit;
    font-weight: 400;
}

p {
    padding: 0;
    margin: 0 0 2.1rem;
}

b,
strong {
    font-weight: 700;
}

.wrapper {
    @extend %wrapper;
}

.brand {
    position: fixed;
    top: 5px;
    right: 5px;    
    max-width: 75px;
    max-height: 75px;
    width: 25vw;
    height: 25vw;
    z-index: 80001;
    border-radius: 50%;
    padding: 3px;
    background-color: #222;
    background-image: url(https://www.highlyprospective.com/wp-content/uploads/2018/07/logo.png);
    background-size: calc(100% - 6px);
    background-position: center;
    background-repeat: no-repeat;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        border-radius: 50%;
        box-shadow: 0 0 3px $gray--dark;
        transition: box-shadow 0.2s ease-out;
    }

    &:hover {
        cursor: pointer;

        &::before {
            box-shadow: 0 0 3px $green--brand;
            transition: box-shadow 0.2s ease-in;
        }
    }

    a {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
    }
}


#menu--display {
    display: none;

    ~ label {
        @extend %button--base;
        margin: 0.6rem;
    }

    ~ nav {
        display: none;
        opacity: 0;
        transition: opacity 0.15s ease-in-out;
        background: $gray--dark;
        box-shadow: 0 5px 10px $gray--dark;
    }

    &:checked ~ nav {
        display: block;
        opacity: 1;
        transition: opacity 0.15s ease-in-out;

        ul {
            display: flex;
            flex-direction: column;
        }
    }

    @media screen and (min-width: 35em) {
        ~ nav,
        &:checked ~ nav {
            display: block;
            opacity: 1;

            .menu {
                flex-direction: row;
            }
        }

        ~ label {
            display: none;
        }
    }
}

#site-header {
    background: $gray--dark;
    font-size: 1.5rem;
    height: $top-bar--height;
    position: fixed;
    position: sticky;
    top: 0;
    z-index: 2000;
    width: 100vw;
    box-shadow: 0 5px 10px $gray--dark;

    .menu {
        margin: 0;
        padding: 0;
        display: flex;
    }

    .menu-item {
        list-style: none;
        padding: 0;

        > a {
            display: block;
            color: $white;
            text-decoration: none;
            padding: 1em;
            transition: box-shadow 0.2s ease-in-out,
                        color 0.2s ease-in-out;

            &:hover {
                color: $green--brand;
                text-shadow: 0 0 2px $green--brand;
            }
        }

        &.current-menu-item {
            > a {
                font-weight: 700;
                color: $green--brand;
            }
        }
    }

    .menu-item-has-children {
        position: relative;

        > a {
            padding-right: 3.2rem;
            cursor: pointer;
            
            &::before {
                @extend %fa-icon;
                @extend .fas;
                content: '\f107';
                cursor: pointer;
                width: 3.2rem;
                height: 4.8rem;
                font-size: 1.8rem;
                position: absolute;
                top: 0;
                right: 0;
                z-index: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: transform 0.2s ease-in-out;
                text-shadow: none;
            }
        }
            

        .sub-menu {
            display: none;
            margin-left: 1em;
            border-left: 0.3rem solid $green--brand;
            padding: 0;

    
            @media screen and (min-width: 35em) {
                position: absolute;
                background: $gray--dark;
                margin-left: 0;
                box-sizing: border-box;
                width: 100%;
            }
        }

        &.menu-item-is-active {
            a::before {
                transform: rotate(-180deg);
                color: $green--brand;
            }

            .sub-menu {
                display: block;
            }
        }
    }
}

.menu-social-container {
    .menu {
        margin: 0;
        padding: 0;
        display: flex;
        li {
            list-style: none;
        }
        a {
            text-decoration: none;
            padding: 0.3rem;

            &:hover::before {
                background: $green--brand;
                color: $gray--dark;
            }
            &::before {
                @extend %fa-icon;
                @extend .fab;
                color: $green--brand;
                font-size: 2.7rem;
                border: 0.12rem solid $green--brand;
                border-radius: 50%;
                display: inline-flex;
                width: 5.2rem;
                height: 5.2rem;
                align-items: center;
                justify-content: center;
                margin: 0.6rem;
            }
        }

        span {
            display: none;
        }
    }
    .social {
        &--facebook {
            a::before {
                content: '\f39e';
            }
        }
        &--instagram {
            a::before {
                content: '\f16d';
            }
        }
        &--twitter {
            a::before {
                content: '\f099';
            }
        }
        &--youtube {
            a::before {
                content: '\f167';
            }
        }
    }
}


////////
// FOOTER
//
#contact-us {
    $gap: 4.5rem;
    padding-top: $gap;
    margin-top: -$gap;
}
footer{
    background: $gray--dark;
    // box-shadow: 0 -5px 10px $gray--dark;
    position: relative;
    z-index: 1000;
    color: #fff;

    &::before {
        content: '';
        display: block;
        background: $green--brand;
        height: 2px;
        position: sticky;
        top: $top-bar--height;
        z-index: 3;
    }

    .wrapper {
        display: flex;
        flex-wrap: wrap;
        padding: 3rem 0 4rem;
        justify-content: space-between;
    }

    aside + aside {
        margin-top: 3rem;
    }

    aside {
        ul {
            margin: 0;
            padding: 0;
        }
        li {
            list-style: none;
            padding: 0.3rem 0;
        }
        a {
            color: #fff;

            &:hover {
                color: $green--brand;
            }
        }
    }
    
}

.widget-title {
    color: #fff;
    font-family: $font--primary;
    font-size: 2.4rem;
    border-bottom: 1px solid #fff;
    padding-bottom: 0.5em;
    display: inline-block;
    margin: 0.6rem 0 1.2rem;
}

#footer-top,
#footer-left,
#footer-right,
#footer-center,
#footer-bottom {
}

#footer-top,
#footer-bottom {
    width: 100vw;
    flex-grow: 1;
    padding: 1rem;
}
#footer-left,
#footer-right {
    flex-grow: 2;
    padding: 1rem;

    @media screen and (min-width: 35em) {
        max-width: 56rem; 
    }
}
#footer-left {
    padding-right: 2rem;
}
#footer-right {
    padding-left: 2rem;
}

#footer-center {
    flex-grow: 5;
    padding: 1rem;
}
.branding--footer {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
    padding-bottom: 2rem;

    img {
        margin: 0 1.5rem 0 0;
    }

    &::before,
    &::after {
        content: '';
        height: 0.06rem;
        width: 40%;
        background: $green--brand;
    }

    &::before {
        justify-self: right;
    }

    &::after {
        justify-self: left;
    }
}
.icon-email::before {
    @extend %fa-icon;
    @extend .fas;
    content: '\f0e0';
    display: inline-block;
    margin-right: 0.5em;
}
.icon-phone::before {
    @extend %fa-icon;
    @extend .fas;
    content: '\f095';
    display: inline-block;
    margin-right: 0.5em;
}

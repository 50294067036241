$black: #000;
$gray--dark: #1a1a1a;
$gray--medium: #aaa;
$gray--light: #efefef;

$white: #fff;

$green--brand: #61A60E;
$green--dark: #3C6609;
$green--olive: #59611D;
$green--gray: #909985;

$red--danger: #FF4136;
$yellow--warning: #FFDC00;

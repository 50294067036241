@import "./colors";

%button--base {
    padding: 0.9rem 1.5rem;
    color: $white;
    display: inline-block;
    display: inline-flex;
    border: 1px solid $white;
    margin: 0.6em 0;
    border-radius: 0.3rem;
    cursor: pointer;
    font-size: 1.4rem;
    box-shadow: 0 0.2rem 0.3rem rgba(0,0,0,0.3);
}

%button--input {
    @extend %button--base;
    background: transparent;
}

%button--brand {
    border-color: $green--dark;
    background: $green--brand;
    transition: all 0.2s ease-in-out;

    &:hover {
        border-color: $green--brand;
        background: $green--dark;
    }
}


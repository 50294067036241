@import "fa";
@import "button";

form {
    position: relative;

    &.invalid,
    &.failed {
        padding-top: 3rem;

        .required {
            input,
            textarea {
                border-color: $red--danger;
            }    

            .wpcf7-not-valid-tip {
                background: $red--danger;
                color: $gray--dark;
            }
        }
    }

    div.wpcf7-response-output {
        box-sizing: border-box;
        margin: 0;
        position: absolute;
        top: 0;
        width: 100%;
        font-size: 1.2rem;

        &.wpcf7-validation-errors {
            background: $yellow--warning;
            border-color: $yellow--warning;
            color: $black;
        }
        &.wpcf7-mail-sent-ng {
            background: $red--danger;
            border-color: $red--danger;
            color: $black;
        }
    }
}

label {
    &.required {
        .form-label__text::after {
            @extend %fa-icon;
            @extend .fas;
            content: '\f069';   
            display: inline-block;
            color: $red--danger;
            margin-left: 0.6rem;
            font-size: 1rem;
            vertical-align: top;
        }

        .wpcf7-form-control-wrap {
            display: block;
            padding-bottom: 0.9rem;
        }

        .wpcf7-not-valid-tip {
            display: block;
            font-size: 1.2rem;
            font-weight: 700;
            padding: 0.3rem;
            position: absolute;
            right: 0;
        }
    }
}

.form-label {
    display: block;
    margin-bottom: 1.6rem;
    
    &__text {
        display: block;
        color: $white;
        font-size: 1.6rem;
        // padding-left: 0.5em;
    }
}

input[type="text"],
input[type="email"],
input[type="search"],
textarea {
    background: $white;
    border: 0.1rem solid $gray--dark;
    border-radius: 2px;
    padding: 0.8rem 1.2rem;
    font-size: 1.4rem;
    color: $gray--dark;
    box-shadow: inset 0 2px 3px $gray--dark;
    display: block;
    font-family: 'Raleway';
    width: 100%;
    box-sizing: border-box;
}

input[type="submit"],
button[type="submit"] {
    @extend %button--input;
    @extend %button--brand;
}

.search-form {
    label {
    }
    .search-field {

    }
}

button.search-submit {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.icon-search {
    display: inline-block;
    margin-right: 0.5em;
    &::before {
        @extend %fa-icon;
        @extend .fas;
        content: '\f002';
    }
}
